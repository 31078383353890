<template>
  <div class="campaignDetail container">
    <h1>{{ offer.name }}</h1>
    <b-tabs content-class="mt-3">
      <b-tab title="Overview"><deal-overview :offer="offer" /></b-tab>
      <b-tab title="Promoters"><deal-promoters /></b-tab>
      <b-tab title="Insights"><deal-insights /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { OFFER } from "@/graphql/brand/query";
export default {
  data() {
    return {
      id: 0,
      offer: [],
      processing: false,
    };
  },
  components: {
    DealOverview: () =>
      import(
        /* webpackChunkName: "CampaignOverview" */ "@/components/brand/deals/DealOverview.vue"
      ),
    DealPromoters: () =>
      import(
        /* webpackChunkName: "CampaignPromoters" */ "@/components/brand/deals/DealPromoters.vue"
      ),
    DealInsights: () =>
      import(
        /* webpackChunkName: "CampaignInsights" */ "@/components/brand/deals/DealInsights.vue"
      ),
  },
  created() {
    this.id = parseInt(this.$route.params.id);
  },
  apollo: {
    offer: {
      query: OFFER,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
        };
      },
      error(e) {
        this.handleError(e);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.campaignDetail {
  h1 {
    padding-left: 10px;
    font-weight: 700;
  }
  ::v-deep {
    .nav.nav-tabs {
      margin: rem(24px) 0;
      @media screen and (max-width: 991px) {
        margin-bottom: rem(35px);
      }
    }
  }
}
</style>
